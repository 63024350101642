import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.Replay(),
  ],
  replaysOnErrorSampleRate: 1.0,
});
app.use(router);
app.mount('#app');


import {
  defineComponent, ref, onMounted, onUnmounted,
} from 'vue';
import { formatTimeHHMMSS } from '@/utils/date';
import MoonEventDisplay from './moon/MoonEventDisplay.vue';

export default defineComponent({
  name: 'MoonSetAndRise',
  components: { MoonEventDisplay },
  props: {
    moonSet: {
      type: String,
      required: false,
    },
    moonRise: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedMoonSetTime(): string {
      if (!this.moonSet) {
        return '';
      }
      return formatTimeHHMMSS(this.moonSet);
    },
    formattedMoonRiseTime(): string {
      if (!this.moonRise) {
        return '';
      }
      return formatTimeHHMMSS(this.moonRise);
    },
    moonIsUp(): boolean {
      if (!this.moonSet || !this.moonRise) {
        return false;
      }
      const now = new Date();
      const moonSetTime = new Date(this.moonSet);
      const moonRiseTime = new Date(this.moonRise);
      const setBeforeRise = moonSetTime.getTime() < moonRiseTime.getTime();
      return setBeforeRise || (now < moonSetTime && now > moonRiseTime);
    },
    isMoonSetNext(): boolean {
      if (!this.moonSet || !this.moonRise) {
        return false;
      }
      const now = new Date();
      const moonSetTime = new Date(this.moonSet);
      const moonRiseTime = new Date(this.moonRise);
      return moonSetTime.getTime() < moonRiseTime.getTime() && now < moonSetTime;
    },
    isMoonRiseNext(): boolean {
      if (!this.moonSet || !this.moonRise) {
        return false;
      }
      const now = new Date();
      const moonSetTime = new Date(this.moonSet);
      const moonRiseTime = new Date(this.moonRise);
      return moonSetTime.getTime() > moonRiseTime.getTime() && now < moonRiseTime;
    },
    isSmall(): boolean {
      return this.small;
    },
  },
  setup(props) {
    const timeToMoonRise = ref('');
    const timeToMoonSet = ref('');
    const displayCondition = ref('clock');
    let conditionIndex = 0;

    let moonEventTimeInterval: ReturnType<typeof setTimeout>;
    let displayConditionUpdateInterval: ReturnType<typeof setTimeout>;

    const updateTimesToMoonEvents = () => {
      const now = new Date();
      if (props.moonRise) {
        const moonRiseTime = new Date(props.moonRise);
        const timeDifferenceRise = new Date(moonRiseTime.getTime() - now.getTime()).toUTCString();
        timeToMoonRise.value = formatTimeHHMMSS(String(timeDifferenceRise), true);
      }
      if (props.moonSet) {
        const moonSetTime = new Date(props.moonSet);
        const timeDifferenceSet = new Date(moonSetTime.getTime() - now.getTime()).toUTCString();
        timeToMoonSet.value = formatTimeHHMMSS(String(timeDifferenceSet), true);
      }
    };

    const updateDisplayCondition = () => {
      const conditions = ['clock', 'timer'];
      conditionIndex = (conditionIndex + 1) % conditions.length;
      displayCondition.value = conditions[conditionIndex];
    };

    onMounted(() => {
      updateTimesToMoonEvents();
      moonEventTimeInterval = setInterval(updateTimesToMoonEvents, 1000);
      displayConditionUpdateInterval = setInterval(updateDisplayCondition, 15000);
    });

    onUnmounted(() => {
      clearInterval(moonEventTimeInterval);
      clearInterval(displayConditionUpdateInterval);
    });

    return {
      timeToMoonRise,
      timeToMoonSet,
      displayCondition,
    };
  },
});

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "now-showing" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "py-1 semi-transparent"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.astroObjectName)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_2, [
          _createTextVNode(" Aktualnie oglądamy: "),
          _createVNode(_Transition, {
            name: "name-fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("strong", {
                key: `${_ctx.astroObjectName}-name`,
                class: "d-inline-block"
              }, _toDisplayString(_ctx.astroObjectName), 1))
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("h3", _hoisted_3, "Aktualnie oglądamy")),
    _createVNode(_Transition, {
      name: "properties-fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock("div", {
          key: `${_ctx.astroObjectName}-properties`,
          class: "my-1"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.astroProperties, (property) => {
            return (_openBlock(), _createElementBlock("p", {
              class: "my-0",
              key: property.name
            }, [
              _createTextVNode(_toDisplayString(property.name) + ":" + _toDisplayString(' ') + " ", 1),
              _createElementVNode("strong", null, _toDisplayString(property.value), 1)
            ]))
          }), 128))
        ]))
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "name-fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.skyMap && _ctx.skyMap !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.skyMap,
                alt: ""
              }, null, 8, _hoisted_5)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}

import { defineComponent } from 'vue';
import OverlayWrapper from '@/components/OverlayWrapper.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    OverlayWrapper,
  },
});

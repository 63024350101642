import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14163d99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "venusOccultation-container text-white" }
const _hoisted_2 = {
  class: "p-0 m-0",
  style: {
        height: '30px',
      }
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      (_ctx.beforeOccultation)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Początek zakrycia za " + _toDisplayString(_ctx.timeToOccultation), 1))
        : _createCommentVNode("", true),
      (_ctx.duringOccultation)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Koniec zakrycia za " + _toDisplayString(_ctx.timeToOccultationEnd), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.beforeOccultation || _ctx.duringOccultation)
      ? (_openBlock(), _createElementBlock("p", {
          key: _ctx.nearestPhaseName,
          class: "p-0 m-0 subtext"
        }, _toDisplayString(_ctx.nearestPhaseName) + " o " + _toDisplayString(_ctx.nearestPhaseTime), 1))
      : _createCommentVNode("", true)
  ]))
}

import { defineComponent, ref, onMounted } from 'vue';

interface CSSStyles {
  [property: string]: string;
}

interface MoonDiscs {
  element: HTMLElement;
  diameter: number;
  color: string;
  offset?: number;
  opacity?: number;
}

interface MoonPhaseConfig {
  shadowColor: string;
  lightColor: string;
  diameter: number;
  earthshine: number;
  blur: number;
  [property: string]: string | number;
}

export default defineComponent({
  name: 'MoonPhaseGraph',
  props: {
    moonPhase: {
      type: String,
      required: true,
    },
    moonFraction: {
      type: String,
      required: true,
    },
  },
  computed: {
    getMoonFraction(): string {
      return `${Number(this.moonFraction)} %`;
    },
    getMoonPhaseName() {
      const phase = this.moonPhase;
      if (phase === 'NEW_MOON') {
        return 'Nów';
      }
      if (phase === 'WAXING_CRESCENT') {
        return 'Po nowiu';
      }
      if (phase === 'FIRST_QUARTER') {
        return 'Pierwsza kwadra';
      }
      if (phase === 'WAXING_GIBBOUS') {
        return 'Przed pełnią';
      }
      if (phase === 'FULL_MOON') {
        return 'Pełnia';
      }
      if (phase === 'WANING_GIBBOUS') {
        return 'Po pełni';
      }
      if (phase === 'LAST_QUARTER') {
        return 'Ostatnia kwadra';
      }
      return 'Przed nowiem';
    },
  },
  setup(props) {
    const moonContainer = ref<HTMLElement | null>(null);

    const applyCSS = (element: HTMLElement, styles: CSSStyles) => {
      Object.keys(styles).forEach((property) => {
        // eslint-disable-next-line
        element.style[property as any] = styles[property];
      });
    };

    const calculateInnerDiscAttributes = (oDiameter: number, semiPhase: number) => {
      const absolutePhase = Math.abs(semiPhase);
      const innerRCalc = ((1 - absolutePhase) * oDiameter) / 2 || 0.01;
      const innerRadius = innerRCalc / 2 + (oDiameter * oDiameter) / (8 * innerRCalc);
      return {
        diameter: innerRadius * 2,
        offset:
          semiPhase > 0
            ? oDiameter / 2 - innerRCalc
            : -2 * innerRadius + oDiameter / 2 + innerRCalc,
      };
    };

    const drawMoonDiscs = (
      outerDisc: MoonDiscs,
      innerDisc: MoonDiscs,
      blurSize: number,
      isWaxing: boolean,
    ) => {
      const blurredDiameter = innerDisc.diameter - blurSize;
      const blurredOffset = innerDisc.offset || 0 + blurSize / 2;
      applyCSS(outerDisc.element, {
        position: 'relative',
        height: `${outerDisc.diameter}px`,
        width: `${outerDisc.diameter}px`,
        border: '2px solid black',
        backgroundColor: outerDisc.color,
        borderRadius: '100%',
        overflow: 'hidden',
      });
      applyCSS(innerDisc.element, {
        position: 'relative',
        backgroundColor: innerDisc.color,
        borderRadius: '100%',
        height: `${blurredDiameter}px`,
        width: `${blurredDiameter}px`,
        left: isWaxing ? `${blurredOffset}px` : `${blurredOffset}px`,
        top: `${(outerDisc.diameter - 4 - blurredDiameter) / 2}px`,
        backgroundPosition: `${-1 * blurredOffset}px`,
      });
    };

    const createDiv = (container: HTMLElement | null, className?: string) => {
      const div = document.createElement('div');
      if (className) {
        div.className = className;
      }
      if (container) {
        container.appendChild(div);
      }
      return div;
    };

    const configureMoonPhase = (
      outerBox: HTMLElement,
      illumination: number,
      isWaxing: boolean,
      config: MoonPhaseConfig,
    ) => {
      const innerBox = createDiv(
        outerBox,
        illumination >= 0.5 ? 'moon-phase-inner' : 'moon-shadow',
      );
      let outerColor;
      let innerColor;
      let illuminationRef = illumination;
      if (illuminationRef < 0.5) {
        outerColor = config.shadowColor;
        innerColor = config.lightColor;
        if (isWaxing) {
          illuminationRef *= -1;
        }
      } else {
        outerColor = config.lightColor;
        innerColor = config.shadowColor;
        illuminationRef = 1 - illuminationRef;
        if (!isWaxing) {
          illuminationRef *= -1;
        }
      }
      const innerAttributes = calculateInnerDiscAttributes(config.diameter, illuminationRef * 2);

      drawMoonDiscs(
        {
          element: outerBox,
          diameter: config.diameter,
          color: outerColor,
        },
        {
          element: innerBox,
          diameter: innerAttributes.diameter,
          color: innerColor,
          offset: innerAttributes.offset,
          opacity: 1 - config.earthshine,
        },
        config.blur as number,
        isWaxing,
      );
    };

    onMounted(() => {
      const phase = props.moonPhase;
      const illumination = Number(props.moonFraction) / 100;
      const isWaxing = ['NEW_MOON', 'WAXING_CRESCENT', 'FIRST_QUARTER', 'WAXING_GIBBOUS'].includes(
        phase,
      );

      const config = {
        shadowColor: '#1f1f1f',
        lightColor: '#1f1f1f',
        diameter: 120,
        earthshine: 0,
        blur: 3,
      };
      const moonPhaseElement = createDiv(
        moonContainer.value,
        illumination < 0.5 ? 'moon-phase-inner' : 'moon-shadow',
      );
      configureMoonPhase(moonPhaseElement, illumination, isWaxing, config);
    });

    return {
      moonContainer,
    };
  },
});

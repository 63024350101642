import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bef9d51"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "eclipse-container"
}
const _hoisted_2 = { class: "timeline-container pb-3" }
const _hoisted_3 = { class: "timeline" }
const _hoisted_4 = { class: "label-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eclipseData, (time, phase) => {
              return (_openBlock(), _createElementBlock("div", {
                key: phase,
                class: "marker",
                style: _normalizeStyle({
            left: _ctx.calculatePosition(time) + '%',
            display: phase === 'start' || phase === 'end' ? 'none' : 'block',
          })
              }, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getPhaseName(phase)), 1)
              ], 4))
            }), 128)),
            _createElementVNode("div", {
              class: "timeleft text-white",
              style: _normalizeStyle({ width: _ctx.timeLeftPosition + '%' })
            }, null, 4)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
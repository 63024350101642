
import { defineComponent } from 'vue';
import { Property } from '@/types/event_types';
import { state } from '@/types/socket';

export default defineComponent({
  name: 'NowShowing',
  props: {},
  computed: {
    astroObject() {
      return state.staticData?.nowShowing;
    },
    astroObjectName() {
      return state.staticData?.nowShowing ? state.staticData.nowShowing.name : '';
    },
    skyMap() {
      return state.staticData?.nowShowing ? state.staticData.nowShowing.skymap : '';
    },
    astroProperties() {
      if (!this.astroObject?.properties) {
        return [];
      }
      console.log({ properties: this.astroObject.properties });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.astroObject.properties.filter(
        (property: Property) => property.value !== null && property.value !== '',
      );
    },
  },
});

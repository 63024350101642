import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e1e3947"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "moon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_moon_phase_graph = _resolveComponent("moon-phase-graph")!
  const _component_moon_set_and_rise = _resolveComponent("moon-set-and-rise")!
  const _component_moon_altitude = _resolveComponent("moon-altitude")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.moonData && _ctx.moonData.moonPhase && _ctx.moonData.moonFraction)
      ? (_openBlock(), _createBlock(_component_moon_phase_graph, {
          key: _ctx.moonData.moonPhase + _ctx.moonData.moonFraction,
          moonPhase: _ctx.moonData.moonPhase,
          moonFraction: _ctx.moonData.moonFraction,
          moonAltitude: _ctx.moonData.moonAltitude
        }, null, 8, ["moonPhase", "moonFraction", "moonAltitude"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({
        'moon-info': true,
        'moon-info--animate': _ctx.moonData && _ctx.moonData.moonAltitude,
      })
    }, [
      (_ctx.moonData && _ctx.moonInfoOpacity[0])
        ? (_openBlock(), _createBlock(_component_moon_set_and_rise, {
            key: String('' + _ctx.moonData.moonSet + _ctx.moonData.moonRise),
            moonSet: _ctx.moonData.moonSet,
            moonRise: _ctx.moonData.moonRise,
            small: !!(_ctx.moonData.moonPhase && _ctx.moonData.moonFraction)
          }, null, 8, ["moonSet", "moonRise", "small"]))
        : _createCommentVNode("", true),
      (_ctx.moonData && _ctx.moonInfoOpacity[1])
        ? (_openBlock(), _createBlock(_component_moon_altitude, {
            key: String(_ctx.moonData.moonAltitude),
            moonAltitude: _ctx.moonData.moonAltitude
          }, null, 8, ["moonAltitude"]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39db65d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-conditions-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sky_conditions = _resolveComponent("sky-conditions")!
  const _component_sky_brightness = _resolveComponent("sky-brightness")!
  const _component_air_temperature = _resolveComponent("air-temperature")!
  const _component_air_humidity = _resolveComponent("air-humidity")!
  const _component_wind_speed = _resolveComponent("wind-speed")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.observationConditions && _ctx.observationConditions.conditions)
      ? (_openBlock(), _createBlock(_component_sky_conditions, {
          key: _ctx.observationConditions.conditions,
          conditions: _ctx.observationConditions.conditions
        }, null, 8, ["conditions"]))
      : _createCommentVNode("", true),
    (_ctx.observationConditions && _ctx.observationConditions.skyBrightness)
      ? (_openBlock(), _createBlock(_component_sky_brightness, {
          key: 1,
          skyBrightness: _ctx.observationConditions.skyBrightness
        }, null, 8, ["skyBrightness"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            class: "scroll-conditions",
            key: _ctx.activeCondition ? String(_ctx.activeCondition) : 'nocondition'
          }, [
            (
            _ctx.activeCondition === 'temperature' &&
            _ctx.observationConditions &&
            _ctx.observationConditions.temperature
          )
              ? (_openBlock(), _createBlock(_component_air_temperature, {
                  key: 0,
                  temperature: _ctx.observationConditions.temperature
                }, null, 8, ["temperature"]))
              : _createCommentVNode("", true),
            (
            _ctx.activeCondition === 'humidity' &&
            _ctx.observationConditions &&
            _ctx.observationConditions.humidity
          )
              ? (_openBlock(), _createBlock(_component_air_humidity, {
                  key: 1,
                  humidity: _ctx.observationConditions.humidity
                }, null, 8, ["humidity"]))
              : _createCommentVNode("", true),
            (_ctx.activeCondition === 'wind' && _ctx.observationConditions && _ctx.observationConditions.wind)
              ? (_openBlock(), _createBlock(_component_wind_speed, {
                  key: 2,
                  wind: _ctx.observationConditions.wind
                }, null, 8, ["wind"]))
              : _createCommentVNode("", true)
          ]))
        ]),
        _: 1
      })
    ])
  ], 64))
}
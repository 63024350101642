export type Phase = 'p1' | 'p2' | 'p3' | 'p4' | 'p5' | 'p6';

const timelineData = {
  start: '2024-05-15T18:45:00.000Z',
  p1: '2024-05-15T19:06:00.000Z', // ISS
  p2: '2024-05-15T20:01:00.000Z', // Zmierzch astronomiczny
  p3: '2024-05-15T20:43:00.000Z', // ISS
  p4: '2024-05-15T21:24:00.000Z', // Początek nocy astronomicznej
  p5: '2024-05-15T22:23:00.000Z', // ISS
  p6: '2024-05-15T22:36:00.000Z', // Północ słoneczna
  end: '2024-05-15T22:50:00.000Z', // koniec
};

const dictionaryShort = {
  p1: 'ISS',
  p2: 'Początek zmierzchu astronomicznego',
  p3: 'ISS',
  p4: 'Początek nocy astronomicznej',
  p5: 'ISS',
  p6: 'Północ słoneczna',
  end: '',
};

const dictionaryLong = {
  p1: 'Przelot ISS',
  p2: 'Początek zmierzchu astronomicznego',
  p3: 'Przelot ISS',
  p4: 'Początek nocy astronomicznej',
  p5: 'Przelot ISS',
  p6: 'Północ słoneczna',
  end: '',
};

const PHASES:Phase[] = [
  'p1',
  'p2',
  'p3',
  'p4',
  'p5',
  'p6',
];

export {
  timelineData, dictionaryShort, dictionaryLong, PHASES,
};

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "moon-phase-graph-container position-relative fit-content" }
const _hoisted_2 = {
  class: "moon-phase-graph pe-2",
  ref: "moonContainer"
}
const _hoisted_3 = { class: "moon-text" }
const _hoisted_4 = { class: "moon-fraction-text" }
const _hoisted_5 = { class: "moon-phase-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getMoonFraction), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getMoonPhaseName), 1)
      ])
    ])
  ]))
}

import { defineComponent, onMounted, ref } from 'vue';
import { msToTime } from '@/utils/date';
import {
  timelineData, dictionaryShort, PHASES, Phase,
} from './iss15052004';

export default defineComponent({
  name: 'timelineView',

  setup() {
    const nearestPhaseName = ref<string>('');
    const nearestPhaseTime = ref<string>('');
    const beforeOccultation = ref<boolean>(true);
    const duringOccultation = ref<boolean>(false);
    const timeLeftPosition = ref<number>(0);

    const getNearestPhase = (): Phase => {
      const now = new Date().getTime();
      return PHASES.reduce((prev, curr) => {
        const prevDiff = new Date(timelineData[prev]).getTime() - now;
        const currDiff = new Date(timelineData[curr]).getTime() - now;

        const isCurrInFuture = currDiff > 0;

        const isPrevInFuture = prevDiff > 0;

        if (isCurrInFuture && isPrevInFuture) {
          return currDiff < prevDiff ? curr : prev;
        }
        if (isCurrInFuture) {
          return curr;
        }
        return prev;
      });
    };

    const isBeforeOccultation = (): boolean => {
      const now = new Date().getTime();
      const occultationStart = new Date(timelineData.p1).getTime();
      return now < occultationStart;
    };

    const isDuringOccultation = (): boolean => {
      const now = new Date().getTime();
      const occultationEnd = new Date(timelineData.p2).getTime();
      return now > occultationEnd;
    };

    const getNearestPhaseTime = (): string => {
      const calc = new Date(timelineData[getNearestPhase()]).toLocaleTimeString();
      return calc;
    };
    const getNearPhaseName = (): string => dictionaryShort[getNearestPhase()] || '';
    onMounted(() => {
      setInterval(() => {
        const start = new Date(timelineData.start).getTime();
        const end = new Date(timelineData.end).getTime();
        const current = new Date().getTime();
        // timeToOccultation.value = msToTime(
        //   new Date(timelineData.p1).getTime() - new Date().getTime(),
        // );
        // timeToOccultationEnd.value = msToTime(
        //   new Date(timelineData.p2).getTime() - new Date().getTime(),
        // );
        nearestPhaseName.value = getNearPhaseName();
        nearestPhaseTime.value = getNearestPhaseTime();
        beforeOccultation.value = isBeforeOccultation();
        duringOccultation.value = isDuringOccultation();
        timeLeftPosition.value = ((current - start) / (end - start)) * 100;
      }, 1000);
    });

    return {
      // timeToOccultation,
      // timeToOccultationEnd,
      beforeOccultation,
      duringOccultation,
      nearestPhaseName,
      nearestPhaseTime,
      timeLeftPosition,
    };
  },
  data() {
    return {
      timelineData,
    };
  },
  methods: {
    calculatePosition(time: any) {
      const start = new Date(this.timelineData.start).getTime();
      const end = new Date(this.timelineData.end).getTime();
      const current = new Date(time).getTime();
      return ((current - start) / (end - start)) * 100;
    },
    getPhaseName(phase: string): string {
      return dictionaryShort[phase as Phase] || '';
    },
  },
  computed: {
    getStartTime(): string {
      return new Date(timelineData.start).toLocaleTimeString();
    },

    getEndTime(): string {
      return new Date(timelineData.end).toLocaleTimeString();
    },
  },
});

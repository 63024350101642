
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WindSpeed',
  props: {
    wind: {
      type: String,
      required: true,
    },
  },
  computed: {
    getWind(): string {
      return `${String(this.wind)} km/h`;
    },
  },
});

import { createWebHistory, createRouter } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import MoonEclipseClockView from '@/views/MoonEclipse/MoonEclipseClockView.vue';
import MoonEclipseView from '@/views/MoonEclipse/MoonEclipseView.vue';
import VenusOccultationClockView from '@/views/VenusOccultation/VenusOccultationClockView.vue';
import VenusOccultationView from '@/views/VenusOccultation/VenusOccultationView.vue';
import TimelineClockView from '@/views/Timeline/TimelineClockView.vue';
import TimelineView from '@/views/Timeline/TimelineView.vue';

const routes = [
  {
    path: '/:username',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/:username/mooneclipse',
    name: 'MoonEclipse',
    component: MoonEclipseView,
  },
  {
    path: '/:username/mooneclipseclock',
    name: 'MoonEclipseClock',
    component: MoonEclipseClockView,
  },
  {
    path: '/:username/venusoccultation',
    name: 'VenusOccultation',
    component: VenusOccultationView,
  },
  {
    path: '/:username/venusoccultationclock',
    name: 'VenusOccultationClock',
    component: VenusOccultationClockView,
  },
  {
    path: '/:username/timeline',
    name: 'Timeline',
    component: TimelineView,
  },
  {
    path: '/:username/timelineclock',
    name: 'TimelineClock',
    component: TimelineClockView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

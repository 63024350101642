
import { defineComponent, onMounted, ref } from 'vue';
import {
  timelineData, dictionaryLong, PHASES, Phase,
} from './iss15052004';

export default defineComponent({
  name: 'TimelineClockView',

  setup() {
    const nearestPhaseName = ref<string>('');
    const nearestPhaseTime = ref<string>('');
    const hasNextPhase = ref<boolean>(false);
    const nearestPhaseTimeTo = ref<string>('');
    const showTime = ref<boolean>(true);

    const getNearestPhase = (): Phase | false => {
      const now = new Date().getTime();
      const nextPhase = PHASES.reduce((prev, curr) => {
        const prevDiff = prev ? new Date(timelineData[prev]).getTime() - now : 0;
        const currDiff = curr ? new Date(timelineData[curr]).getTime() - now : 0;

        const isCurrInFuture = currDiff > 0;
        const isPrevInFuture = prevDiff > 0;

        if (isCurrInFuture && isPrevInFuture) {
          return currDiff < prevDiff ? curr : prev;
        }

        if (isCurrInFuture) {
          return curr;
        }

        return prev; // Add return statement here
      }); // Provide an initial value for the reduce method
      const nextPhaseTime = nextPhase ? new Date(timelineData[nextPhase]).getTime() : 0;
      const isNextPhaseInFuture = nextPhaseTime - now > 0;
      return isNextPhaseInFuture ? nextPhase : false;
    };

    const getNearestPhaseTimeTo = (): string => {
      const nearestPhase = getNearestPhase();
      if (!nearestPhase) return '';

      const now = new Date().getTime();
      const nextPhaseTime = new Date(timelineData[nearestPhase]).getTime();
      const difference = nextPhaseTime - now;

      const hours = Math.floor(difference / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    };

    const getNearestPhaseTime = (): string => {
      const nearestPhase = getNearestPhase();
      if (!nearestPhase) return '';
      const time = new Date(timelineData[nearestPhase]);
      const hours = time.getHours().toString().padStart(2, '0');
      const minutes = time.getMinutes().toString().padStart(2, '0');
      const calc = `${hours}:${minutes}`;
      return calc;
    };

    const getNearPhaseName = (): string => {
      const nearestPhase = getNearestPhase();
      if (!nearestPhase) return '';
      return dictionaryLong[nearestPhase] || '';
    };

    const getHasNextPhase = (): boolean => !!getNearestPhase();

    onMounted(() => {
      setInterval(() => {
        nearestPhaseName.value = getNearPhaseName();
        nearestPhaseTime.value = getNearestPhaseTime();
        nearestPhaseTimeTo.value = getNearestPhaseTimeTo();
        hasNextPhase.value = getHasNextPhase();
      }, 1000);
      setInterval(() => {
        showTime.value = !showTime.value;
      }, 15000);
    });

    return {
      nearestPhaseName,
      nearestPhaseTime,
      nearestPhaseTimeTo,
      hasNextPhase,
      showTime,
    };
  },

  computed: {
    getStartTime(): string {
      return new Date(timelineData.start).toLocaleTimeString();
    },

    getEndTime(): string {
      return new Date(timelineData.end).toLocaleTimeString();
    },
  },
});

import { reactive } from 'vue';
import { io } from 'socket.io-client';
import { StaticData } from '@/types/event_types';

/* eslint-disable @typescript-eslint/no-var-requires */
const Sentry = require('@sentry/vue');

interface SocketState {
  connected: boolean;
  staticData: StaticData|null;
}

export const state: SocketState = reactive({
  connected: false,
  staticData: null,
});

export const socket = io(process.env.VUE_APP_SOCKET_URL!, {
  reconnection: true,
  reconnectionDelay: 500,
});

socket.on('connect', () => {
  state.connected = true;
  const username = window.location.pathname.split('/')[1];
  socket.emit('join', `overlay-${username}`);
});

socket.on('disconnect', () => {
  state.connected = false;
});

socket.on('static-data-update', (staticData: StaticData) => {
  state.staticData = staticData;
});

// socket.on('observation-conditions', (observationConditions: ObservationConditions) => {
//   console.log({ observationConditions });
//   state.observationConditions = observationConditions;
// });

socket.on('error', (error) => {
  console.error(error);
  Sentry.captureException(error);
});

socket.on('reconnect', (attempt) => {
  console.log(`reconnect: ${attempt}`);
  Sentry.captureMessage(`reconnect: ${attempt}`);
});
socket.on('reconnect_attempt', (attempt) => {
  console.log(`reconnect_attempt: ${attempt}`);
  Sentry.captureMessage(`reconnect_attempt: ${attempt}`);
});
socket.on('reconnect_failed', () => {
  console.log('reconnect_failed');
  Sentry.captureMessage('reconnect_failed');
});

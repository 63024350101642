
import { defineComponent, onMounted, ref } from 'vue';
import { msToTime } from '@/utils/date';
import { occultationData } from './venusoccultation.json';

type Phase = 'p1' | 'p2';
const PHASES: Phase[] = ['p1', 'p2'];

const PHASE_DICTIONARY = {
  p1: 'Poczatek zakrycia',
  p2: 'Koniec zakrycia',
};

export default defineComponent({
  name: 'MoonEclipseClock',

  setup() {
    const timeToOccultation = ref<string>('');
    const timeToOccultationEnd = ref<string>('');
    const nearestPhaseName = ref<string>('');
    const nearestPhaseTime = ref<string>('');
    const beforeOccultation = ref<boolean>(true);
    const duringOccultation = ref<boolean>(false);

    const getNearestPhase = (): Phase => {
      const now = new Date().getTime();
      return PHASES.reduce((prev, curr) => {
        const prevDiff = new Date(occultationData[prev]).getTime() - now;
        const currDiff = new Date(occultationData[curr]).getTime() - now;

        const isCurrInFuture = currDiff > 0;

        const isPrevInFuture = prevDiff > 0;

        if (isCurrInFuture && isPrevInFuture) {
          return currDiff < prevDiff ? curr : prev;
        }
        if (isCurrInFuture) {
          return curr;
        }
        return prev;
      });
    };

    const isBeforeOccultation = (): boolean => {
      const now = new Date().getTime();
      const occultationStart = new Date(occultationData.p1).getTime();
      return now < occultationStart;
    };

    const isDuringOccultation = (): boolean => {
      const now = new Date().getTime();
      const occultationEnd = new Date(occultationData.p2).getTime();
      const occultationStart = new Date(occultationData.p1).getTime();
      return now > occultationStart && now < occultationEnd;
    };

    const getNearestPhaseTime = (): string => {
      const calc = new Date(occultationData[getNearestPhase()]).toLocaleTimeString();
      return calc;
    };
    const getNearPhaseName = (): string => PHASE_DICTIONARY[getNearestPhase()] || '';

    onMounted(() => {
      setInterval(() => {
        timeToOccultation.value = msToTime(
          new Date(occultationData.p1).getTime() - new Date().getTime(),
        );
        timeToOccultationEnd.value = msToTime(
          new Date(occultationData.p2).getTime() - new Date().getTime(),
        );
        nearestPhaseName.value = getNearPhaseName();
        nearestPhaseTime.value = getNearestPhaseTime();
        beforeOccultation.value = isBeforeOccultation();
        duringOccultation.value = isDuringOccultation();
      }, 1000);
    });

    return {
      timeToOccultation,
      timeToOccultationEnd,
      beforeOccultation,
      duringOccultation,
      nearestPhaseName,
      nearestPhaseTime,
    };
  },

  computed: {
    getStartTime(): string {
      return new Date(occultationData.start).toLocaleTimeString();
    },

    getEndTime(): string {
      return new Date(occultationData.end).toLocaleTimeString();
    },
  },
});

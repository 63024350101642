
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AirTemperature',
  props: {
    temperature: {
      type: String,
      required: true,
    },
  },
  computed: {
    getTemperature():string {
      return `${String(this.temperature)} °C`;
    },
  },
});

<template>
  <div
    id="overlay"
    class="container-fluid overflow-hidden"
    v-bind:class="{ 'no-obs': !connectedToObs }"
  >
    <div v-if="!connectedToSocket" class="spinner-border" role="status"></div>
    <p v-if="!isUsernameProvided" class="text-danger">Błędny URL. Wprowadź nazwę użytkownika</p>
    <main class="row">
      <aside class="col d-flex row justify-start align-content-start position-relative">
        <div class="now-showing-container">
          <now-showing v-if="isNowShowingActive" />
        </div>
        <div class="observation-conditions-container">
          <moon-data />
          <observation-conditions v-if="isObservetionConditionsActive" />
        </div>
      </aside>

      <section></section>

      <aside class="col"></aside>
    </main>
  </div>
</template>

<script>
import NowShowing from '@/components/overlays/NowShowing.vue';
import ObservationConditions from '@/components/overlays/ObservationConditions.vue';
import { state as obsState } from '@/types/obs';
import { state as socketState } from '@/types/socket';
import MoonData from '@/components/overlays/MoonData.vue';

export default {
  name: 'OverlayWrapper',
  components: {
    NowShowing,
    MoonData,
    ObservationConditions,
  },
  computed: {
    connectedToSocket() {
      return socketState.connected;
    },
    connectedToObs() {
      return obsState.connected;
    },
    isNowShowingActive() {
      if (!socketState.staticData || !socketState.staticData.nowShowing) {
        return false;
      }
      const { name, properties } = socketState.staticData.nowShowing;
      if (
        !name
        && properties.filter((property) => property.value === null || property.value === '').length
          === properties.length
      ) {
        return false;
      }
      return true;
    },
    isUsernameProvided() {
      return window.location.pathname.split('/')[1].length > 0;
    },
    isObservetionConditionsActive() {
      return socketState.staticData?.observationConditions;
    },
  },
};
</script>

<style lang="scss">
#overlay {
  position: relative;
  height: 1080px;
  width: 1920px;
  top: 0;
  left: 0;

  > header,
  > footer {
    min-height: 15%;
    height: 15%;
  }

  > main {
    min-height: 70%;
    height: 70%;

    > section,
    > aside {
      min-height: 1080px;
      height: 1080px;
    }
  }
}

.no-obs {
  background-image: url('~@/assets/img/sample-background.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.now-showing-container {
  height: 300px;
  margin-bottom: 30px;
}
.observation-conditions-container {
  position: absolute;
  bottom: 285px;
  font-size: 0.8em;
  line-height: 1em;
}
</style>

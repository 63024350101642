
import { defineComponent, onMounted, ref } from 'vue';
import { msToTime } from '@/utils/date';
import { eclipseData } from './mooneclipse.json';

type Phase = 'u1' | 'p1' | 'max' | 'u4' | 'p4';
const PHASES: Phase[] = ['u1', 'p1', 'max', 'u4', 'p4'];

const PHASE_DICTIONARY = {
  p1: 'Rozpoczęcie fazy półcieniowej',
  u1: 'Rozpoczęcie fazy częściowej',
  max: 'Maksymalna faza',
  u4: 'Koniec fazy częściowej',
  p4: 'Koniec fazy półcieniowej',
};

export default defineComponent({
  name: 'MoonEclipseClock',

  setup() {
    const timeToMaximum = ref<string>('');
    const nearestPhaseName = ref<string>('');
    const nearestPhaseTime = ref<string>('');
    const beforeMaximum = ref<boolean>(true);
    const maximumNext = ref<boolean>(true);
    const show = ref<boolean>(true);

    const getNearestPhase = (): Phase => {
      const now = new Date().getTime();
      return PHASES.reduce((prev, curr) => {
        const prevDiff = new Date(eclipseData[prev]).getTime() - now;
        const currDiff = new Date(eclipseData[curr]).getTime() - now;

        // Check if the current phase is in the future
        const isCurrInFuture = currDiff > 0;

        // Check if the previous phase is in the future
        const isPrevInFuture = prevDiff > 0;

        if (isCurrInFuture && isPrevInFuture) {
          // If both phases are in the future, choose the one closest to now
          return currDiff < prevDiff ? curr : prev;
        }
        if (isCurrInFuture) {
          // If only the current phase is in the future, choose it
          return curr;
        }
        // If neither phase is in the future or only the previous phase is,
        // stick with the previous phase or move to the next phase respectively
        return prev;
      });
    };

    const isBeforeMaximum = (): boolean => {
      const calc = new Date().getTime() < new Date(eclipseData.max).getTime();
      return calc;
    };
    const isMaximumNext = (): boolean => getNearestPhase() === 'max';
    const getNearestPhaseTime = (): string => {
      const calc = new Date(eclipseData[getNearestPhase()]).toLocaleTimeString();
      return calc;
    };
    const getNearPhaseName = (): string => PHASE_DICTIONARY[getNearestPhase()] || '';

    onMounted(() => {
      setInterval(() => {
        timeToMaximum.value = msToTime(new Date(eclipseData.max).getTime() - new Date().getTime());
        nearestPhaseName.value = getNearPhaseName();
        nearestPhaseTime.value = getNearestPhaseTime();
        beforeMaximum.value = isBeforeMaximum();
        maximumNext.value = isMaximumNext();
        if (new Date().getTime() > new Date(eclipseData.end).getTime()) {
          show.value = false;
        } else {
          show.value = true;
        }
      }, 1000);
    });

    return {
      timeToMaximum,
      nearestPhaseName,
      nearestPhaseTime,
      beforeMaximum,
      maximumNext,
      show,
    };
  },

  computed: {
    getStartTime(): string {
      return new Date(eclipseData.start).toLocaleTimeString();
    },

    getEndTime(): string {
      return new Date(eclipseData.end).toLocaleTimeString();
    },
  },
});


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AirHumidity',
  props: {
    humidity: {
      type: String,
      required: true,
    },
  },
  computed: {
    getHumidity():string {
      return `${String(this.humidity)} %`;
    },
  },
});


import { defineComponent, ref, onMounted } from 'vue';
import { state } from '@/types/socket';
import MoonPhaseGraph from './_components/moonPhaseGraph.vue';
import MoonSetAndRise from './_components/moonSetAndRise.vue';
import moonAltitude from './_components/moonAltitude.vue';

export default defineComponent({
  components: {
    MoonPhaseGraph,
    MoonSetAndRise,
    moonAltitude,
  },
  name: 'MoonData',
  props: {},
  computed: {
    moonData() {
      return state.staticData?.moonData;
    },
  },
  setup() {
    const moonInfoOpacity = ref([true, false]);
    const index = 0;

    onMounted(() => {
      setInterval(() => {
        if (state.staticData?.moonData && state.staticData?.moonData.moonAltitude) {
          moonInfoOpacity.value = [!moonInfoOpacity.value[0], !moonInfoOpacity.value[1]];
        }
      }, 45000);
    });

    return {
      moonInfoOpacity,
    };
  },
});

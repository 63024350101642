import { reactive } from 'vue';

interface ObsState {
  connected: boolean;
}

interface ObsData {
  version: string;
}

export const state: ObsState = reactive({
  connected: !!window.obsstudio,
});

export function data(): ObsData {
  return reactive({
    version: window.obsstudio.pluginVersion,
  });
}

export function startRecording(): void {
  window.obsstudio.startRecording();
}


import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MoonEventDisplay',
  props: {
    moonIsBeforeSet: {
      type: Boolean,
      required: true,
    },
    displayCondition: {
      type: String,
      required: true,
    },
    formattedMoonTime: {
      type: String,
      required: true,
    },
    timeToMoonEvent: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    moonEventLabel():string {
      return this.moonIsBeforeSet ? 'do wschodu' : 'do zachodu';
    },
  },
});

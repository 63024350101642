import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2522e09a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "eclipse-container text-white"
}
const _hoisted_2 = {
  class: "p-0 m-0",
  style: {
        height: '30px',
      }
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          (_ctx.beforeMaximum)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Faza maksymalna za " + _toDisplayString(_ctx.timeToMaximum), 1))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(), _createElementBlock("p", {
          key: _ctx.nearestPhaseName,
          class: "p-0 m-0 subtext"
        }, _toDisplayString(_ctx.nearestPhaseName) + " o " + _toDisplayString(_ctx.nearestPhaseTime), 1))
      ]))
    : _createCommentVNode("", true)
}

import { defineComponent, ref, onMounted } from 'vue';
import { state } from '@/types/socket';
import SkyConditions from './_components/skyConditions.vue';
import SkyBrightness from './_components/skyBrightness.vue';
import AirTemperature from './_components/airTemperature.vue';
import AirHumidity from './_components/airHumidity.vue';
import WindSpeed from './_components/windSpeed.vue';

export default defineComponent({
  components: {
    SkyConditions,
    SkyBrightness,
    AirTemperature,
    AirHumidity,
    WindSpeed,
  },
  name: 'ObservationConditions',
  props: {},
  computed: {
    observationConditions() {
      return state.staticData?.observationConditions;
    },
  },
  setup() {
    const activeCondition = ref(
      state.staticData?.observationConditions
        ? state.staticData?.observationConditions.temperature
            || state.staticData?.observationConditions.humidity
            || state.staticData?.observationConditions.wind
        : null,
    );
    let index = 0;

    onMounted(() => {
      setInterval(() => {
        if (state.staticData?.observationConditions) {
          const defaultConditions = ['temperature', 'humidity', 'wind'];
          const conditions = defaultConditions.filter(
            (condition) => state.staticData?.observationConditions
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              && !!(state.staticData?.observationConditions as any)[condition],
          );
          if (conditions.length !== 0) {
            index = (index + 1) % conditions.length;
            activeCondition.value = conditions[index];
          } else {
            activeCondition.value = null;
            index = 0;
          }
        }
      }, 7000);
    });

    return {
      activeCondition,
    };
  },
});

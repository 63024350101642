
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MoonAltitude',
  props: {
    moonAltitude: {
      type: String,
      required: true,
    },
  },
  computed: {
    getMoonAltitude(): string {
      return `${Number(this.moonAltitude)}°`;
    },
  },
});
